<template>
  <div v-if="ready">
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          TOTOT
        </div>
      </div>
      <div class="card-body">
        <div class="embed-responsive embed-responsive-21by9">
          <iframe class="embed-responsive-item" :src="link" id="doc" ref="doc" style="min-height:3000px;width:100%"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import Vue from 'vue';
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue)
export default {
  data () {
    return {
      ready:true,
      link:"https://sign.test.universign.eu/sig/#/?id=68d5d159-33ef-451e-91ce-14e5b6c4f259"
    }
  },
  components: {
  },
  beforeMount() {
  },
  mounted() {
      // setTimeout(() => {
      //   console.log(this.$refs);
      // },1000)
  },
  computed:{
    iframe(){
      return document.getElementById("doc").contentWindow.document;
    }
  },
  methods: {
  },
  watch:{
  }
}
</script>
<style media="screen">
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
</style>
